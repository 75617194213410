<template>
  <v-container>
    <v-combobox
      v-model="chips"
      :items="courseTags"
      chips
      clearable
      label="Filter by category"
      multiple
      prepend-icon="mdi-filter-variant"
      solo
    >
      <template v-slot:selection="{ attrs, item, select, selected }">
        <v-chip
          v-bind="attrs"
          :input-value="selected"
          close
          @click="select"
          @click:close="remove(item)"
        >
          <strong>{{ item }}</strong
          >&nbsp;
          <span>(interest)</span>
        </v-chip>
      </template>
    </v-combobox>

    <!-- NO DATA AVAILABLE -->

    <div v-if="events.length === 0">
      <p v-show="!firstLoad">No data available for this partner</p>
    </div>

    <v-data-iterator
      :items="events"
      hide-default-footer
      loading-text=""
      loading="true"
      :items-per-page="-1"
      :search="search"
    >
      <template v-slot:default="props">
        <v-row>
          <v-col
            cols="12"
            md="4"
            lg="4"
            class="events-col"
            v-for="event in props.items"
            :key="event.id"
          >
            <v-skeleton-loader
              v-if="firstLoad"
              type="image, article"
            ></v-skeleton-loader>
            <event
              :event="event"
              v-show="!firstLoad"
              :mobile="mobile"
              @filterTag="filterByTag"
            ></event>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>
<script>
import moment from "moment";
import event from "@/components/events/event.vue";
export default {
  created() {
    this.isMobile();
  },
  components: { event },
  data() {
    return {
      selectedTag: null,
      search: "",
      mobile: 600,
      drawer: true,
      firstLoad: true,
      chips: [],
      sections: [
        { title: "News" },
        { title: "Search" },
        { title: "Courses" },
        { title: "Events" }
      ]
    };
  },
  watch: {
    getProvider(partner) {
      this.$router.push({ name: "Events", query: { partner } });
    }
  },
  computed: {
    events() {
      var events = this.$store.getters["events/getEvents"];
      var provider = this.getProvider;
      var filterEvents = [];

      // sort by the latest
      events.sort((a, b) => {
        let fa = moment(a.date, "DD MMMM YYYY @ hh:mm");
        var fb = moment(b.date, "DD MMMM YYYY @ hh:mm");

        if (moment(fa).isAfter(fb)) {
          return 1;
        }
        if (moment(fa).isBefore(fb)) {
          return -1;
        }
        return 0;
      });

      // remove events that have past
      var now = moment();
      events = events.filter(function(event) {
        var date = moment(event.date, "DD MMMM YYYY @ hh:mm");

        return moment(date).isSameOrAfter(now);
      });

      if (this.getProvider && this.getProvider !== "All Events") {
        events = events.filter(event => {
          return event.source === provider;
        });
      }

      if (this.getSearchQuery) {
        events = events.filter(event => {
          return (
            event.summary
              .toLowerCase()
              .includes(this.getSearchQuery.toLowerCase()) ||
            event.title
              .toLowerCase()
              .includes(this.getSearchQuery.toLowerCase()) ||
            event.source
              .toLowerCase()
              .includes(this.getSearchQuery.toLowerCase())
          );
        });
        return events;
      }

      if (this.chips.length === 0) {
        return events;
      }
      events.forEach(event => {
        event.tags.forEach(tag => {
          if (this.chips.includes(tag)) {
            filterEvents.push(event);
          }
        });
      });

      return filterEvents;
    },
    getSearchQuery() {
      return this.$store.getters["search/getQuery"];
    },
    courseTags() {
      var courses = this.$store.getters["events/getEvents"];
      var allCoursesTags = [];
      courses.forEach(course => {
        allCoursesTags.push(course.tags);
      });
      var mergeAllTags = [].concat(...allCoursesTags);
      let unique = [...new Set(mergeAllTags)];

      unique.sort((a, b) => {
        let fa = a.toLowerCase(),
          fb = b.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      return unique;
    },
    getProvider() {
      return this.$store.getters["articles/getSelectedProvider"];
    }
  },
  mounted() {
    let scope = this;
    const route = this.$route;

    if (route.query && route.query.partner) {
      // Partner filtering using direct URLs
      this.$store.commit("articles/setSelectedProvider", route.query.partner);
    }

    this.$store.dispatch("events/retrieveEvents").then(() => {
      setTimeout(function() {
        scope.firstLoad = false;
      }, 50);
    });
    // this.$store.dispatch("events/retrieveEventTags");
  },
  methods: {
    filterByTag(tag) {
      tag = this.courseTags.find(t => t === tag);

      if (!this.chips.includes(tag)) {
        this.chips.push(tag);
      }
    },
    goTo(section) {
      if (section.title === "News") {
        this.$router.push({ name: "News" });
      } else {
        this.$router.push("/" + section.title.toLowerCase()).catch(() => {});
      }
    },
    isMobile() {
      if (screen.width <= 1024) {
        this.drawer = false;
        this.mobile = 365;
      } else {
        this.drawer = true;
        this.mobile = 600;
      }
    },
    remove(item) {
      this.chips.splice(this.chips.indexOf(item), 1);
      this.chips = [...this.chips];
    }
  }
};
</script>
<style scoped>
.filter-title {
  position: absolute;
  margin-top: 10px;
  margin-left: -100px;
  font-family: Playfair Display;
  font-style: normal;
  font-weight: bold;
  font-size: 12px !important;
  line-height: 38px;
  /* or 167% */

  display: flex;
  align-items: center;

  color: #4e2a69 !important;
}

.event-row {
  display: initial;
}

.date-range {
  position: static;
  width: 253px;
  height: 20px;
  left: 0px;
  top: 0px;

  font-family: Lora;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  /* identical to box height, or 160% */

  display: flex;
  align-items: center;
  letter-spacing: 0.16em;

  color: #1b2475;
}

.range-accent {
  width: 100%;
  height: 2px;
  left: 0px;
  top: 20px;

  border-color: #1b2475 !important;
}

.events-col {
  flex-basis: auto;
}
</style>
