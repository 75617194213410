<template>
  <div>
    <article class="event-article">
      <v-card :loading="loading" max-width="600">
        <template slot="progress">
          <v-progress-linear
            color="deep-purple"
            height="10"
            indeterminate
          ></v-progress-linear>
        </template>
        <div class="home-img-container" v-if="event.source === 'CodeClan'">
          <v-img
            contain
            class="home-article-img-public"
            src="../../../public/platform-logos/codeclan-logo.png"
          ></v-img>
        </div>
        <div
          class="home-img-container px-4"
          v-else-if="event.source === 'The Data Lab'"
        >
          <template v-if="event.imgUrl != null">
            <v-img contain class="home-article-img" :src="event.imgUrl">
              <template v-slot:placeholder>
                <v-img
                  contain
                  class="home-article-img"
                  src="../../../public/platform-logos/thedatalab-logo.png"
                ></v-img>
              </template>
            </v-img>
          </template>
          <template v-else>
            <v-img
              contain
              class="home-article-img"
              src="../../../public/platform-logos/thedatalab-logo.png"
            ></v-img>
          </template>
        </div>
        <div class="home-img-container" v-else>
          <v-img contain class="home-article-img" :src="event.imgUrl"></v-img>
        </div>

        <v-card-title class="partner-title">
          {{ event.source.toUpperCase() }}</v-card-title
        >
        <v-card-subtitle class="event-subtitle">
          {{ event.title }}</v-card-subtitle
        >
        <v-card-text>
          <v-row align="center" class="mx-0">
            <div class="grey--text event-date">
              {{ event.date }}
            </div>
          </v-row>
          <v-chip
            class="chip"
            label
            link
            v-for="tag in event.tags"
            :key="tag"
            @click="filterTag(tag)"
          >
            {{ tag }}
          </v-chip>

          <div class="my-4 subtitle-1"></div>

          <div>
            {{ event.summary }}
          </div>
        </v-card-text>

        <v-divider class="mx-4"></v-divider>

        <v-card-actions>
          <v-btn
            color="#75bdc7"
            text
            target="_blank"
            v-bind:href="event.urlLink"
          >
            Learn More
          </v-btn>
        </v-card-actions>
      </v-card>
      <!-- <div v-if="event.source === 'Code Clan'" class="img-container">
        <img
          class="event-img"
          src="../../../public/platform-logos/codeclan-logo.png"
        />
      </div>
      <div v-else class="img-container">
        <img class="event-img" :src="event.imgUrl" />
      </div>
      <h5 class="event-source" v-bind:style="{ color: event.colour }">
        {{ event.source.toUpperCase() }}
      </h5>
      <hr class="event-accent" v-bind:style="{ borderColor: event.colour }" />
      <h6 class="date" v-bind:style="{ color: event.colour }">
        {{ event.date }}
      </h6>
      <h2 class="event-title">{{ event.title }}</h2>
      <h5 class="event-summary">{{ event.summary }}</h5>
      <a target="_blank" class="learn-more" v-bind:href="event.urlLink"
        >Learn More</a
      >
      <v-chip class="chip" label link v-for="tag in event.tags" :key="tag">
        {{ tag }}
      </v-chip> -->
    </article>
  </div>
</template>
<script>
export default {
  props: ["event", "loading", "mobile"],
  methods: {
    filterTag(tag) {
      this.$emit("filterTag", tag);
    }
  }
};
</script>
<style scoped>
@media (min-width: 320px) {
  .partner-title {
    font-size: 15px !important;
  }
}
@media (min-width: 1025px) {
  .partner-title {
    font-size: 15px !important;
  }
}

.event-subtitle {
  margin-bottom: -15px;
}

/* .event-article {
  padding: 0 1rem 0 0.8rem;
} */

.home-article-img {
  /* Rectangle 6 */
  width: 100%;
  height: 166px;
  object-fit: cover;
  background-color: white;
}
.home-article-img-public {
  /* Rectangle 6 */
  width: 100%;
  height: 166px;
  object-fit: contain;
  background-color: white;
}

div.home-img-container {
  max-width: 100%;
}

/* div.img-container-event {
  max-width: 100%;
  padding: 0 0 0.25rem 0;
} */

.date {
  /* Date */

  position: static;
  height: 20px;
  left: 0px;
  top: 30px;

  font-family: Lora;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 19px;
  /* identical to box height, or 192% */

  display: flex;
  align-items: center;
  letter-spacing: 0.16em;

  color: #1b2475;
}

.date-range {
  position: static;
  width: 253px;
  height: 20px;
  left: 0px;
  top: 0px;

  font-family: Lora;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  /* identical to box height, or 160% */

  display: flex;
  align-items: center;
  letter-spacing: 0.16em;

  color: #1b2475;
}

.range-accent {
  width: 100%;
  height: 2px;
  left: 0px;
  top: 20px;

  border-color: #1b2475 !important;
}

.event-source {
  position: static;
  width: 253px;
  height: 20px;
  left: 0px;
  top: 0px;

  font-family: Lora;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  /* identical to box height, or 160% */

  display: flex;
  align-items: center;
  letter-spacing: 0.16em;

  color: #4e2a69;
}

.event-accent {
  width: 100%;
  height: 2px;
  left: 0px;
  top: 20px;

  border-color: #1b2475;
}

.event-title {
  position: static;
  width: 327px;
  left: 0px;
  top: 22px;

  font-family: Playfair Display;
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 36px;
  /* or 167% */

  display: flex;
  align-items: center;

  color: #323b3c;
}

.event-date {
  margin-bottom: -12px;
}

.event-summary {
  position: static;
  width: 327px;
  left: 0px;
  top: 99px;

  margin-bottom: 0.1rem;

  font-family: Lora;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  /* or 160% */

  display: flex;
  align-items: center;

  color: #516163;
}

.learn-more {
  position: static;
  width: 76px;
  height: 20px;
  left: 0px;
  top: 202px;

  font-family: Lora;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height, or 137% */

  display: flex;
  align-items: center;

  text-decoration-line: underline;

  /* NESCOL */

  color: #4e2a69 !important;
}

.chip {
  justify-content: center;
  width: 92px;
  height: 28px !important;
  left: 10px;
  top: 18px;
  background: #19365e !important;
  border-radius: 5px;
  margin-bottom: 1rem;
  margin-right: 15px !important;
  margin-left: -10px;

  font-family: Big Caslon;
  font-style: normal;
  font-weight: normal;
  font-size: 10px !important;
  line-height: 18px;
  color: #fff !important;
}

.date-range-container {
  margin-bottom: 0.5rem;
}
</style>
